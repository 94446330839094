<template>
  <v-app>
    <div class="d-flex justify-start" style="width:100%;">
      <div :style="!$vuetify.breakpoint.xs?'min-width:200px;':''">
        <drawer
          :drawer="drawer"
          :sidebarColor="sidebarColor"
          :sidebarTheme="sidebarTheme"
          :items="drawerItems"
          @goHome="$router.push({name: '홈화면'})">
        </drawer>
      </div>
      <div style="width:100%">
        <app-bar
          background="bg-transparent"
          has-bg
          @drawer-toggle="toggleDrawer($event)"
          :toggle-active="drawer"
          :navbarFixed="navbarFixed"></app-bar>
        <fade-transition
          :duration="200"
          origin="center top"
          mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </v-app>
</template>

<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import {
  FadeTransition
} from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: {
    FadeTransition,
    Drawer,
    AppBar
  },
  data() {
    return {
      drawer: null,
      drawerItems: [{
        title: "홈",
        items: [{
          title: "견적요청보기",
          link: "/pages/dashboard/quotation-requests",
          icon: "fas fa-clipboard-list",
          active: false
        }, {
          title: "견적관리",
          link: "/pages/dashboard/quotations",
          icon: "fas fa-clipboard-list",
          active: false
        }, {
          title: "품목관리",
          link: "/pages/dashboard/quotation-items",
          icon: "fas fa-clipboard-list",
          active: false
        }, {
          title: "발주관리",
          link: "/pages/dashboard/orders",
          icon: "fas fa-clipboard-list",
          active: false
        }, {
          title: "납품관리",
          link: "/pages/dashboard/deliverys",
          icon: "fas fa-clipboard-list",
          active: false
        }, {
          title: "정산관리",
          link: "/pages/dashboard/settlement",
          icon: "fas fa-clipboard-list",
          active: false
        }, {
          title: "상담관리",
          link: "/pages/dashboard/quotation-customer-services",
          icon: "fas fa-clipboard-list",
          active: false
        }, {
          title: "공지사항",
          link: "/pages/dashboard/seller-notice",
          icon: "fas fa-clipboard-list",
          active: false
        }]
      },
        {
          title: "기타",
          items: [{
            title: "설정",
            icon: "fas fa-user-cog",
            active: false,
            items: [{
              title: "내 정보",
              link: "/pages/dashboard/my-info",
              active: false
            }, {
              title: "기타 설정",
              link: "/pages/dashboard/settings",
              active: false
            }, {
              title: "비밀번호 변경",
              link: "/pages/dashboard/changepw",
              active: false
            }]
          }]
        }
      ],
      showSettingsDrawer: false,
      sidebarColor: "success",
      sidebarTheme: "transparent",
      navbarFixed: false
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    toggleDrawer(event) {
      this.$nextTick(() => {
        this.drawer = false;
        this.$nextTick(() => {
          this.drawer = event;
        });
      });
    }
  },
  async mounted() {
    this.initScrollbar();
  }
};
</script>

<style lang="scss"></style>
